import { Record, Store } from './Store';
import scaleImage from './lib/scaleImage';

interface Asset extends Record {
  name: string;
  uri: string;
}

export class AssetStore extends Store<{
  assets: {
    key: string;
    value: Asset;
  };
}> {
  #cachedAssets = new Map<string, Asset>();
  #cachedImages = new Map<string, HTMLImageElement>();
  #cachedScaledImages = new Map<string, HTMLCanvasElement>();
  #requiredAssets: string[] = [
    './assets/city/bulb.png',
    './assets/city/food.png',
    './assets/city/gold.png',
    './assets/city/luxury.png',
    './assets/city/people_content_f.png',
    './assets/city/people_content_m.png',
    './assets/city/people_happy_f.png',
    './assets/city/people_happy_m.png',
    './assets/city/people_luxury.png',
    './assets/city/people_science.png',
    './assets/city/people_tax.png',
    './assets/city/people_unhappy_f.png',
    './assets/city/people_unhappy_m.png',
    './assets/city/pollution.png',
    './assets/city/sad.png',
    './assets/city/production.png',
    './assets/city/trade.png',
    './assets/cursor/go.png',
    './assets/cursor/torch.png',
    './assets/general/texture_1.png',
    './assets/general/texture_2.png',
    './assets/improvements/irrigation.png',
    './assets/improvements/mine.png',
    './assets/improvements/pollution.png',
    './assets/improvements/railroad_e.png',
    './assets/improvements/railroad_se.png',
    './assets/improvements/railroad_n.png',
    './assets/improvements/railroad_ne.png',
    './assets/improvements/railroad_nw.png',
    './assets/improvements/railroad_s.png',
    './assets/improvements/railroad_sw.png',
    './assets/improvements/railroad_w.png',
    './assets/improvements/road_e.png',
    './assets/improvements/road_n.png',
    './assets/improvements/road_ne.png',
    './assets/improvements/road_nw.png',
    './assets/improvements/road_s.png',
    './assets/improvements/road_se.png',
    './assets/improvements/road_sw.png',
    './assets/improvements/road_w.png',
    './assets/map/city.png',
    './assets/map/fog_e.png',
    './assets/map/fog_n.png',
    './assets/map/fog_s.png',
    './assets/map/fog_w.png',
    './assets/map/fort.png',
    './assets/map/fortify.png',
    './assets/map/hut.png',
    './assets/status/pollution_0.png',
    './assets/status/pollution_25.png',
    './assets/status/pollution_50.png',
    './assets/status/pollution_75.png',
    './assets/status/science_0.png',
    './assets/status/science_25.png',
    './assets/status/science_50.png',
    './assets/status/science_75.png',
    './assets/terrain/arctic.png',
    './assets/terrain/arctic_e.png',
    './assets/terrain/arctic_es.png',
    './assets/terrain/arctic_esw.png',
    './assets/terrain/arctic_ew.png',
    './assets/terrain/arctic_n.png',
    './assets/terrain/arctic_ne.png',
    './assets/terrain/arctic_nes.png',
    './assets/terrain/arctic_nesw.png',
    './assets/terrain/arctic_new.png',
    './assets/terrain/arctic_ns.png',
    './assets/terrain/arctic_nsw.png',
    './assets/terrain/arctic_nw.png',
    './assets/terrain/arctic_s.png',
    './assets/terrain/arctic_sw.png',
    './assets/terrain/arctic_w.png',
    './assets/terrain/coal.png',
    './assets/terrain/coast_sprite.png',
    './assets/terrain/desert.png',
    './assets/terrain/desert_e.png',
    './assets/terrain/desert_es.png',
    './assets/terrain/desert_esw.png',
    './assets/terrain/desert_ew.png',
    './assets/terrain/desert_n.png',
    './assets/terrain/desert_ne.png',
    './assets/terrain/desert_nes.png',
    './assets/terrain/desert_nesw.png',
    './assets/terrain/desert_new.png',
    './assets/terrain/desert_ns.png',
    './assets/terrain/desert_nsw.png',
    './assets/terrain/desert_nw.png',
    './assets/terrain/desert_s.png',
    './assets/terrain/desert_sw.png',
    './assets/terrain/desert_w.png',
    './assets/terrain/doe.png',
    './assets/terrain/fish.png',
    './assets/terrain/forest.png',
    './assets/terrain/forest_e.png',
    './assets/terrain/forest_es.png',
    './assets/terrain/forest_esw.png',
    './assets/terrain/forest_ew.png',
    './assets/terrain/forest_n.png',
    './assets/terrain/forest_ne.png',
    './assets/terrain/forest_nes.png',
    './assets/terrain/forest_nesw.png',
    './assets/terrain/forest_new.png',
    './assets/terrain/forest_ns.png',
    './assets/terrain/forest_nsw.png',
    './assets/terrain/forest_nw.png',
    './assets/terrain/forest_s.png',
    './assets/terrain/forest_sw.png',
    './assets/terrain/forest_w.png',
    './assets/terrain/gems.png',
    './assets/terrain/gold.png',
    './assets/terrain/grassland.png',
    './assets/terrain/grassland_e.png',
    './assets/terrain/grassland_es.png',
    './assets/terrain/grassland_esw.png',
    './assets/terrain/grassland_ew.png',
    './assets/terrain/grassland_n.png',
    './assets/terrain/grassland_ne.png',
    './assets/terrain/grassland_nes.png',
    './assets/terrain/grassland_nesw.png',
    './assets/terrain/grassland_new.png',
    './assets/terrain/grassland_ns.png',
    './assets/terrain/grassland_nsw.png',
    './assets/terrain/grassland_nw.png',
    './assets/terrain/grassland_s.png',
    './assets/terrain/grassland_sw.png',
    './assets/terrain/grassland_w.png',
    './assets/terrain/hills.png',
    './assets/terrain/hills_e.png',
    './assets/terrain/hills_es.png',
    './assets/terrain/hills_esw.png',
    './assets/terrain/hills_ew.png',
    './assets/terrain/hills_n.png',
    './assets/terrain/hills_ne.png',
    './assets/terrain/hills_nes.png',
    './assets/terrain/hills_nesw.png',
    './assets/terrain/hills_new.png',
    './assets/terrain/hills_ns.png',
    './assets/terrain/hills_nsw.png',
    './assets/terrain/hills_nw.png',
    './assets/terrain/hills_s.png',
    './assets/terrain/hills_sw.png',
    './assets/terrain/hills_w.png',
    './assets/terrain/horse.png',
    './assets/terrain/jungle.png',
    './assets/terrain/jungle_e.png',
    './assets/terrain/jungle_es.png',
    './assets/terrain/jungle_esw.png',
    './assets/terrain/jungle_ew.png',
    './assets/terrain/jungle_n.png',
    './assets/terrain/jungle_ne.png',
    './assets/terrain/jungle_nes.png',
    './assets/terrain/jungle_nesw.png',
    './assets/terrain/jungle_new.png',
    './assets/terrain/jungle_ns.png',
    './assets/terrain/jungle_nsw.png',
    './assets/terrain/jungle_nw.png',
    './assets/terrain/jungle_s.png',
    './assets/terrain/jungle_sw.png',
    './assets/terrain/jungle_w.png',
    './assets/terrain/land.png',
    './assets/terrain/mountains.png',
    './assets/terrain/mountains_e.png',
    './assets/terrain/mountains_es.png',
    './assets/terrain/mountains_esw.png',
    './assets/terrain/mountains_ew.png',
    './assets/terrain/mountains_n.png',
    './assets/terrain/mountains_ne.png',
    './assets/terrain/mountains_nes.png',
    './assets/terrain/mountains_nesw.png',
    './assets/terrain/mountains_new.png',
    './assets/terrain/mountains_ns.png',
    './assets/terrain/mountains_nsw.png',
    './assets/terrain/mountains_nw.png',
    './assets/terrain/mountains_s.png',
    './assets/terrain/mountains_sw.png',
    './assets/terrain/mountains_w.png',
    './assets/terrain/oasis.png',
    './assets/terrain/ocean.png',
    './assets/terrain/oil.png',
    './assets/terrain/plains.png',
    './assets/terrain/plains_e.png',
    './assets/terrain/plains_es.png',
    './assets/terrain/plains_esw.png',
    './assets/terrain/plains_ew.png',
    './assets/terrain/plains_n.png',
    './assets/terrain/plains_ne.png',
    './assets/terrain/plains_nes.png',
    './assets/terrain/plains_nesw.png',
    './assets/terrain/plains_new.png',
    './assets/terrain/plains_ns.png',
    './assets/terrain/plains_nsw.png',
    './assets/terrain/plains_nw.png',
    './assets/terrain/plains_s.png',
    './assets/terrain/plains_sw.png',
    './assets/terrain/plains_w.png',
    './assets/terrain/river_e.png',
    './assets/terrain/river_es.png',
    './assets/terrain/river_esw.png',
    './assets/terrain/river_ew.png',
    './assets/terrain/river_mouth_e.png',
    './assets/terrain/river_mouth_n.png',
    './assets/terrain/river_mouth_s.png',
    './assets/terrain/river_mouth_w.png',
    './assets/terrain/river.png',
    './assets/terrain/river_n.png',
    './assets/terrain/river_ne.png',
    './assets/terrain/river_nes.png',
    './assets/terrain/river_nesw.png',
    './assets/terrain/river_new.png',
    './assets/terrain/river_ns.png',
    './assets/terrain/river_nsw.png',
    './assets/terrain/river_nw.png',
    './assets/terrain/river_s.png',
    './assets/terrain/river_sw.png',
    './assets/terrain/river_w.png',
    './assets/terrain/seal.png',
    './assets/terrain/shield.png',
    './assets/terrain/game.png',
    './assets/terrain/swamp.png',
    './assets/terrain/swamp_e.png',
    './assets/terrain/swamp_es.png',
    './assets/terrain/swamp_esw.png',
    './assets/terrain/swamp_ew.png',
    './assets/terrain/swamp_n.png',
    './assets/terrain/swamp_ne.png',
    './assets/terrain/swamp_nes.png',
    './assets/terrain/swamp_nesw.png',
    './assets/terrain/swamp_new.png',
    './assets/terrain/swamp_ns.png',
    './assets/terrain/swamp_nsw.png',
    './assets/terrain/swamp_nw.png',
    './assets/terrain/swamp_s.png',
    './assets/terrain/swamp_sw.png',
    './assets/terrain/swamp_w.png',
    './assets/terrain/tundra.png',
    './assets/terrain/tundra_e.png',
    './assets/terrain/tundra_es.png',
    './assets/terrain/tundra_esw.png',
    './assets/terrain/tundra_ew.png',
    './assets/terrain/tundra_n.png',
    './assets/terrain/tundra_ne.png',
    './assets/terrain/tundra_nes.png',
    './assets/terrain/tundra_nesw.png',
    './assets/terrain/tundra_new.png',
    './assets/terrain/tundra_ns.png',
    './assets/terrain/tundra_nsw.png',
    './assets/terrain/tundra_nw.png',
    './assets/terrain/tundra_s.png',
    './assets/terrain/tundra_sw.png',
    './assets/terrain/tundra_w.png',
    './assets/units/tank.png',
    './assets/units/artillery.png',
    './assets/units/battleship.png',
    './assets/units/bomber.png',
    './assets/units/cannon.png',
    './assets/units/caravan.png',
    './assets/units/carrier.png',
    './assets/units/catapult.png',
    './assets/units/horseman.png',
    './assets/units/chariot.png',
    './assets/units/combat_1.png',
    './assets/units/combat_2.png',
    './assets/units/combat_3.png',
    './assets/units/combat_4.png',
    './assets/units/combat_5.png',
    './assets/units/combat_6.png',
    './assets/units/combat_7.png',
    './assets/units/combat_8.png',
    './assets/units/cruiser.png',
    './assets/units/diplomat.png',
    './assets/units/fighter.png',
    './assets/units/frigate.png',
    './assets/units/ironclad.png',
    './assets/units/knight.png',
    './assets/units/swordman.png',
    './assets/units/mechanizedinfantry.png',
    './assets/units/warrior.png',
    './assets/units/musketman.png',
    './assets/units/nuclear.png',
    './assets/units/spearman.png',
    './assets/units/rifleman.png',
    './assets/units/sail.png',
    './assets/units/settlers.png',
    './assets/units/submarine.png',
    './assets/units/transport.png',
    './assets/units/trireme.png',
  ];

  constructor() {
    super('civ-clone-assets', 'assets', {
      keyPath: 'name',
    });
  }

  async get(path: string): Promise<Asset> {
    if (!this.#cachedAssets.has(path)) {
      this.#cachedAssets.set(path, (await super.get(path))!);
    }

    return this.#cachedAssets.get(path)!;
  }

  async getImage(path: string): Promise<HTMLImageElement> {
    if (!this.#cachedImages.has(path)) {
      const asset = await this.get(path),
        image = document.createElement('img')!;

      image.src = asset.uri;

      this.#cachedImages.set(path, image);
    }

    return this.#cachedImages.get(path)!;
  }

  async getScaled(path: string, scale: number): Promise<HTMLCanvasElement> {
    if (!this.#cachedScaledImages.has(path)) {
      this.#cachedScaledImages.set(
        path,
        scaleImage(await this.getImage(path), scale)
      );
    }

    return this.#cachedScaledImages.get(path)!;
  }

  async hasAllAssets(): Promise<boolean> {
    return (await this.missingAssets()).length === 0;
  }

  async missingAssets(): Promise<string[]> {
    const dbAssets = await this.keys();

    return this.#requiredAssets.filter(
      (requiredAsset) => !dbAssets.includes(requiredAsset)
    );
  }
}

export const assetStore = new AssetStore();
